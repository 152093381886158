import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Interface
import { ServicesProps } from 'components/flex/Services/ServicesShell'
import NavLink from 'components/shared/NavLink'

const Section = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.light};
  padding: 3rem 0 5rem 0;

  @media (min-width: 576px) {
    margin-bottom: 15rem;
  }

  @media (max-width: 575.98px) {
    margin-bottom: 9rem;
  }
`

const Service = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  aspect-ratio: 1/1;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  color: ${({ theme }) => theme.color.primary};
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(0.95);
  }
`

const Icon = styled(Plaatjie)`
  & img {
    object-fit: contain !important;
  }

  @media (min-width: 576px) {
    width: 50%;
  }

  @media (max-width: 575.98px) {
    width: 70%;
  }
`

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.alternate};
  font-family: ${({ theme }) => theme.font.family.secondary};

  @media (min-width: 992px) {
    font-size: ${({ theme }) => theme.font.size.medium};
    line-height: ${({ theme }) => theme.font.size.medium};
  }

  @media (max-width: 575.98px) {
    font-size: ${({ theme }) => theme.font.size.small};
    line-height: ${({ theme }) => theme.font.size.medium};
  }
`

const ServicesWrapper = styled.div`
  margin-bottom: -12rem;
`

const ColContent = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6:first-child {
    font-size: ${({ theme }) => theme.font.size.ultra};
    line-height: ${({ theme }) => theme.font.size.ultra};
    margin-bottom: 0 !important;
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    color: ${({ theme }) => theme.color.light};
    font-size: ${({ theme }) => theme.font.size.mega};
    line-height: ${({ theme }) => theme.font.size.mega};
  }
`

const Services: React.FC<ServicesProps> = ({ fields }) => (
  <Section className="position-relative">
    <div className="container">
      <div className="row">
        <div className="col-md-5">
          <ColContent content={fields.description} />
        </div>
        <div className="col-md-7">
          <ParseContent content={fields.descriptiontwo} />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-xl-11">
          <ServicesWrapper className="row mt-5">
            {fields.services?.map((service, index) => (
              <div className="col-6 col-md-3 mb-4" key={index}>
                <NavLink to={service?.link?.url || '#'}>
                  <Service className="d-flex flex-column justify-content-between align-items-center pt-lg-5 pb-lg-4 py-3 py-lg-0 px-4">
                    <Icon image={service?.icon} alt="" />
                    <Content
                      className="text-center"
                      content={service?.link?.title}
                    />
                  </Service>
                </NavLink>
              </div>
            ))}
          </ServicesWrapper>
        </div>
      </div>
    </div>
  </Section>
)

export default Services
